
import { defineComponent } from "vue";
import config from "@/config.json";

export default defineComponent({
  setup: () => {
    return {
      documents: config.reimbursementDocuments,
    };
  },
});
