
import { defineComponent } from "vue";
import config from "@/config.json";

export default defineComponent({
  setup: () => {
    return {
      registrationUrl: config.registrationUrl,
    };
  },
  mounted: () => {
    window.open(config.registrationUrl, "_blank");
  },
});
