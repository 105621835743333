
import { defineComponent } from "vue";
import { getSortedParticipants } from "@/utils";

export default defineComponent({
  setup: () => {
    return {
      participants: getSortedParticipants(),
    };
  },
});
