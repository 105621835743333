
import { defineComponent } from "vue";
import config from "@/config.json";

export default defineComponent({
  setup: () => {
    return {
      lunchOptions: config.accommodation.lunchOptions,
      dinnerOptions: config.accommodation.dinnerOptions,
    };
  },
});
