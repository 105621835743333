<template>
  <main>
    <slot></slot>
  </main>
</template>

<style>
main {
  border-radius: 12px;
  background-color: rgb(248, 228, 198);
  padding: 12px;
  margin: 0px;
}
</style>
