
import { defineComponent } from "vue";
import config from "@/config.json";

export default defineComponent({
  setup: () => {
    return {
      posterUrl: config.posterUrl,
    };
  },
  mounted: () => {
    window.open(config.posterUrl, "_blank");
  },
});
